import { useNavigate, useState, Link } from "react-router-dom";

const l_top = require("../components/ROM/rom-images/Left_top_Design.png");
const logo = require("../components/ROM/rom-images/TrussLogo.png");
const c_name = require("../components/ROM/rom-images/Company_Name.png");
const r_bottom = require("../components/ROM/rom-images/Right_Bottom_Design.png");

const RomPage = () => {
  //   const [enableVideo, setEnableVideo] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/romSession");
  };

  return (
    <div className="sm:hidden max-sm:h-screen max-sm:bg-[#001D2C] relative">
      <img
        className="absolute w-2/3 h-56 object-left-top object-cover"
        src={l_top}
        alt="left-top-design"
      />

      <div className="max-sm:justify-between flex flex-col gap-2 justify-center items-center pt-[50%]">
        <img
          className="w-48 h-auto max-sm:bg-[#001D2C]"
          src={logo}
          alt="logo"
        />

        <img className="pb-4 w-72" src={c_name} alt="company-name" />
        <p className="text-white text-2xl pb-8">Range of Motion</p>
        <Link
          to={"/romSession"}
          className="w-72 h-20 rounded-full p-2 m-2 bg-[#21A18E] text-white flex items-center justify-center z-50"
        >
          Start Session
        </Link>
      </div>

      <img
        className="absolute h-56 w-2/3 object-cover  object-right-bottom right-0 bottom-0 z-0"
        src={r_bottom}
        alt="right-top-design"
      />
    </div>
  );
};

export default RomPage;
