import { useState } from "react";
import axios from "axios";
import { user } from "../mock/loginMock";
import { useNavigate } from "react-router-dom";

const logo = require("../components/ROM/rom-images/TrussLogo.png");

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userCreds, setUserCreds] = useState(null);
  let navigate = useNavigate();

  const handleSubmit = async () => {
    console.log(email, " ", password);
    // const userCreds = await axios.post(
    //   "https://backend.trusshealth.ai/login/patient",
    //   {
    //     email: email,
    //     password: password,
    //   }
    // );
    // setUserCreds()
    // if (userCreds) {
    //   console.log("wcwx");
    // }

    console.log(user[0].email);

    if (user[0].email === email) {
      console.log("check");
      navigate("/home-page");
    } else {
      alert("Invalid credentials");
    }
  };
  return (
    <div className="sm:hidden max-sm:h-screen max-sm:bg-[#001D2C] relative">
      <div className="max-sm:justify-between flex flex-col gap-2 justify-center items-center pt-[50%]">
        <img src={logo} alt="company-logo" />
        <div className="text-white flex flex-col">
          <input
            className="text-black p-2 m-2 rounded-lg text-center"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="text-black p-2 m-2 rounded-lg text-center"
            value={password}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="text-white bg-[#21A18E] p-2 m-2 rounded-lg"
          type="button"
          onClick={() => handleSubmit()}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
