import { createSlice } from "@reduxjs/toolkit";

const angleDetectedSlice = createSlice({
  name: "angleDetected",
  initialState: {
    // isCaptureStart: false,
    isMin: 20,
    isDifference: 100,
    isMax: 180,
  },
  reducers: {
    updateAngle: (state, action) => {
      //   state.isCaptureStart = !state.isCaptureStart;
      //mutating the state
      // console.log("Display angle ", action.payload);
      state.isMin = action.payload;
      //   state.isMin = action.payload;
      //   state.isMin = action.payload;
    },
  },
});

export const { updateAngle } = angleDetectedSlice.actions;
export default angleDetectedSlice.reducer;

//add a tick mark
