// import { updateAngle } from "src/utils/angleDetectedSlice";
import { updateAngle } from "../../../utils/angleDetectedSlice";
import { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
  DrawingOptions,
} from "@mediapipe/tasks-vision";
import { useDispatch, useSelector } from "react-redux";
import LoadModel from "./LoadModel";

const OpenCam = (prop) => {
  const { displayCam } = prop;
  console.log(prop, " prop checkin ", displayCam);
  const enableDetection = useSelector((store) => store.detect.enableDetection);

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [poseLandMarker, setPoseLandMarker] = useState(null);

  //updating the store
  const dispatch = useDispatch();

  const minAngleUpdate = useSelector((store) => store.angleDetected.isMin);

  let runningMode = "VIDEO";
  const videoHeight = "480px";
  const videoWidth = "480px";

  const startDetection = () => {
    const canvasElement = document.getElementById("output_canvas");

    const canvasCtx = canvasElement.getContext("2d");

    const drawingUtils = new DrawingUtils(canvasCtx);

    //for enabling button to perform detection
    // const hasgetUserMedia = () => navigator.mediaDevices.getUserMedia;
    // if (hasgetUserMedia()) {
    //enable button to start detecting using event listener
    // }
    let length = Object.keys(poseLandMarker).length;

    console.log(webcamRef);
    console.log(length, " chchch");

    if (length > 0 && poseLandMarker && webcamRef.current.stream.active) {
      let lastVideoTime = -1;
      let fl = 0;
      async function renderLoop() {
        console.log("render begins");
        if (webcamRef === null) {
          return;
        }
        const video = document.getElementById("webcam");

        if (video !== null) {
          console.log("after video");

          let startTimeMs = performance.now();

          if (video.currentTime !== lastVideoTime) {
            lastVideoTime = video.currentTime;

            if (!(video.readyState !== undefined && video.readyState === 0))
              poseLandMarker.detectForVideo(video, startTimeMs, (result) => {
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, 640, 480);
                let left = [];
                let right = [];

                const b = result.landmarks;
                const leftIndices = [23, 25, 27];
                const rightIndices = [24, 26, 28];
                if (b.length > 0) {
                  left = b[0].filter((x, idx) => leftIndices.includes(idx));
                  right = b[0].filter((x, idx) => rightIndices.includes(idx));
                }
                if (left.length === 0) {
                  return;
                }

                let pt1 = left[0];
                let pt2 = left[1];
                let pt3 = left[2];

                //cal angle
                function dist(p1, p2) {
                  return Math.sqrt(
                    Math.pow(p1.x - p2.x, 2) +
                      Math.pow(p1.y - p2.y, 2) +
                      Math.pow(p1.z - p2.z, 2)
                  );
                }

                function radians_to_degrees(radians) {
                  return radians * (180 / Math.PI);
                }

                // Function to find the angle in 3D space
                function find_angle(a, b, c) {
                  const ab = dist(a, b);
                  const bc = dist(b, c);
                  const ac = dist(a, c);

                  const angle =
                    (Math.pow(ab, 2) + Math.pow(bc, 2) - Math.pow(ac, 2)) /
                    (2 * ab * bc);
                  return radians_to_degrees(Math.acos(angle));
                }

                let angle_c = find_angle(pt1, pt2, pt3);

                if (angle_c > 0 && angle_c < 180) {
                  dispatch(updateAngle(angle_c));
                }
                //end of angle func

                drawingUtils.drawLandmarks(left, {
                  radius: (data) => {
                    DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1);
                  },
                });

                drawingUtils.drawConnectors(
                  left,
                  PoseLandmarker.POSE_CONNECTIONS,
                  {
                    radius: 2,
                    lineWidth: 1,
                  }
                );

                canvasCtx.restore();
              });
            await new Promise((resolve, reject) =>
              setTimeout(() => resolve(), 2000)
            );
            renderLoop();
          }
        }

        // requestAnimationFrame(() => {
        //   console.log("wdwdd  ", webcamRef, " -------- ", displayCam);
        //   if (displayCam) {
        //     renderLoop();
        //   }
        // });
      }

      if (webcamRef.current.stream.active) {
        renderLoop();
      }
    }
  };

  useEffect(() => {
    if (poseLandMarker) {
      startDetection();
    }
  }, [poseLandMarker]);

  if (displayCam) {
    return (
      <div id="start" className="h-[427px] max-w-[427px] block w-full">
        {poseLandMarker === null && (
          <LoadModel setPoseLandMarker={setPoseLandMarker} />
        )}
        <Webcam
          id="webcam"
          onUserMedia={(stream) => {
            console.log("onuser media");
            if (stream.active) {
              console.log("check  ");
            }
          }}
          ref={webcamRef}
          style={{
            position: "absolute",
            marginRight: "auto",
            marginLeft: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            height: "427px",
          }}
          className="box-border m-2 p-2"
        ></Webcam>
        <canvas
          id="output_canvas"
          ref={canvasRef}
          className="box-border m-2 p-2"
          style={{
            position: "absolute",
            marginRight: "auto",
            marginLeft: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 10,
            height: "427px",
            width: "inherit",
          }}
        ></canvas>
      </div>
    );
  }
};

export default OpenCam;
