import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const RomDialog = ({ showdialogbox }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    console.log("test");
    setOpen(false);
    // navigate("/romSession");
    window.location.reload();
  };

  // const handleAgain = () => {};

  if (showdialogbox) {
    return (
      <div>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle className="text-center"> Confirm Dialog </DialogTitle>
          <h3 className="text-center pt-8" style={{ marginTop: "-10px" }}>
            Range of motion collected
          </h3>
          <br></br>
          <div className="p-2 m-2 flex flex-col justify-center">
            <Link
              to={"/home-page"}
              className="p-2 m-2 bg-[#21A18E] text-center rounded-xl w-48"
            >
              Accept Capture
            </Link>

            <button
              onClick={handleClose}
              className="p-2 m-2 bg-[#21A18E] text-center rounded-xl"
            >
              Do it again
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
};

export default RomDialog;
