import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cameraLogo from "../../Images/cameraLogo.png";

//using prop to open the dialog box
const CameraAccessDialog = (props: { enableCamera: () => Promise<void> }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [camAccess, setCamAccess] = useState<boolean>(false);
  let navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (perm: boolean) => {
    setOpen(false);
    setCamAccess(perm);
    //handle if camera is disabled
    if (perm === false) {
      navigate("/");
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img src={cameraLogo} alt="camera-Logo" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center text-2xl font-bold text-black"
          >
            Please approve access to the camera
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex flex-cols justify-center">
          <div>
            <button
              className="bg-[#21A18E] m-2 w-44 h-10 rounded-xl"
              onClick={() => {
                handleClose(false);
              }}
            >
              Do not approve
            </button>
            <button
              className="bg-[#21A18E] m-2 h-10 w-44 rounded-xl"
              onClick={() => {
                handleClose(true);
                props.enableCamera();
              }}
            >
              Approve
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CameraAccessDialog;
