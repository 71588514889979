import { BrowserRouter, Route, Routes } from "react-router-dom";
import RomSession from "./components/ROM/detect-rom/RomSession";
import RomPage from "./pages/RomPage";
import LoginPage from "./pages/LoginPage";
import ExerciseList from "./components/Exercise/ExerciseList";
import WatchExercise from "./components/Exercise/WatchExercise";
import HomePage from "./pages/HomePage";
import PainInfo from "./components/Pain/PainInfo";

const RouteInfo = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home-page" element={<HomePage />} />
        <Route path="/startRom" element={<RomPage />} />
        <Route path="/romSession" element={<RomSession />} />
        <Route path="/exercise-list" element={<ExerciseList />} />
        <Route path="/watch-video" element={<WatchExercise />} />
        <Route path="/pain" element={<PainInfo />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteInfo;
