import { configureStore } from "@reduxjs/toolkit";
import detectionSlice from "./detectionSlice";
import angleReducer from "./angleDetectedSlice";

const store = configureStore({
  reducer: {
    detect: detectionSlice,
    angleDetected: angleReducer,
  },
});

export default store;
