import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  exerciseLogo,
  goniometerIcon,
  logoutIcon,
  painIcon,
} from "../mock/icons";

const HomePage = () => {
  return (
    <div className="h-screen">
      <div className="sticky top-0 bg-[#01526A] h-24 text-center text-white justify-center flex items-center font-bold text-2xl">
        <div className="flex flex-1 justify-center"> Home</div>
        <div>
          <a href="/">
            <img className="w-10 h-10 p-2" src={logoutIcon} alt="logout" />
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 m-2 p-2 items-center">
        <div className="flex items-center justify-center gap-2 border shadow-sm">
          <Link
            to="/startrom"
            className="w-24 h-20 text-center rounded-xl bg-[#21A18E] flex items-center justify-center"
          >
            Range of motion
          </Link>
          <img src={goniometerIcon} alt="goniometer" className="h-48 w-48" />
        </div>
        <div className="flex items-center justify-center gap-3 border shadow-sm">
          <Link
            to="/exercise-list"
            className="w-24 h-20 rounded-xl bg-[#21A18E] text-center flex items-center justify-center"
          >
            Exercise
          </Link>
          <img className="w-48 h-48" src={exerciseLogo} alt="biceps curl" />
        </div>
        <div className="flex items-center justify-center border shadow-sm">
          <Link
            to="/pain"
            className="w-24 h-20 rounded-xl bg-[#21A18E] text-center flex items-center justify-center"
          >
            Recovery level
          </Link>
          <img src={painIcon} alt="recovery icon" className="h-48 w-48" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
