import React, { useState } from "react";
import { arrowIcon } from "../../mock/icons";
import { kneePain } from "../../Images/Pain/pain";
import { painCheckbox } from "../../utils/label/painLabel";
import { p } from "../../utils/label/painLabel";

const PainInfo = () => {
  //   painCheckbox.map((items) => {
  //     console.log(Object.keys(painCheckbox[0])[0]);
  //   });
  const data = painCheckbox;
  // console.log("dd ", Object.keys(p));
  // console.log("ww ", p.p1[0]);

  // console.log(p.p1[0].title, " ", typeof p);

  // Object.keys(p).map((oneKey, i) => {
  //   console.log("onekey ", oneKey, " ", i);
  // });
  const [never, setNever] = useState(false);
  const [one, setOne] = useState(false);
  // const [two, setTwo] = useState(false);
  // const [three, setThree] = useState(false);
  // const [four, setFour] = useState(false);
  // const [five, setFive] = useState(false);
  // const [six, setSix] = useState(false);
  // const [seven, setSeven] = useState(false);
  // const [eight, setEight] = useState(false);
  // const [nine, setNine] = useState(false);
  // const [ten, setTen] = useState(false);
  // const [eleven, setEleven] = useState(false);
  // const [Twelve, setTwelve] = useState(false);

  const handleOnChange = (prop: any) => {
    console.log("check", prop);
    setNever(never ? false : true);
  };

  return (
    <div className="bg-blue-100">
      <div className="sticky top-0 bg-[#01526A] h-24 text-center text-white justify-center flex items-center font-bold text-2xl">
        <a href="/home-page">
          <img src={arrowIcon} className="w-11 h-11 p-2" alt="back-arrow" />
        </a>
        <div className="text-white font-bold text-2xl flex flex-1 justify-center">
          Pain
        </div>
      </div>

      <div className="m-2 p-2">
        <img
          className="items-center justify-center container"
          src={kneePain}
          alt="person holding knee"
        />
      </div>
      <p className="ml-3 text-4xl text-[#01526A]">Pain:</p>
      {(Object.keys(p) as (keyof typeof p)[]).map((oneKey, i) => {
        return (
          <div className="p-2 m-2 justify-center items-center">
            <p className="m-1 p-1 bg-white rounded-lg">{p[oneKey][0].title}</p>
            <div className="p-1 m-1 space-x-2 flex">
              {Object.keys(p[oneKey][1]).map((items) => {
                return (
                  <div className="">
                    <label className="space-x-1 flex">
                      <input
                        type="checkbox"
                        id="Never"
                        name="Never"
                        // checked={never}
                        // onChange={() => handleOnChange(p[oneKey])}
                      />
                      <p>{items}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      <div className="flex items-center justify-center m-2 p-2">
        <button className="w-40 h-14 bg-[#21A18E] rounded-full text-white">
          Next Step
        </button>
      </div>
    </div>
  );
};

export default PainInfo;
