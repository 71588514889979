import { Link } from "react-router-dom";
import { list } from "../../mock/exerciseList";
import { arrowIcon } from "../../mock/icons";

const ExerciseList = () => {
  const data = list.exercise;

  return (
    <div>
      <div className="sticky top-0 bg-[#01526A] h-24 flex items-center justify-center">
        <a href="/home-page">
          <img src={arrowIcon} className="w-11 h-11 p-2" alt="back-arrow" />
        </a>
        <div className="text-white font-bold text-2xl flex flex-1 justify-center">
          Exercise list
        </div>
      </div>
      <div className="m-2 p-2 flex flex-col gap-2">
        {data.map((items) => {
          return (
            <div key={items.exerciseId}>
              <div className="p-8 m-2 grid grid-cols-2 border border-gray-200 rounded-xl gap-4 shadow-sm`">
                <Link
                  to="/watch-video"
                  state={{
                    vidPath: items.exerciseVideoPath,
                    name: items.exerciseName,
                  }}
                >
                  <img
                    className="w-48"
                    src={items.exerciseImagePath}
                    alt="exercise-video"
                  />
                </Link>

                <div className="flex flex-col pl-4 gap-2">
                  <p className="font-bold">{items.exerciseName}</p>
                  <p className="font-normal text-gray-400">Daily 10 mins</p>
                  <div className="w-36 h-2 bg-gradient-to-r from-[#21A18E] rounded-md"></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExerciseList;

//checkboxes,
