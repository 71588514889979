import { createSlice } from "@reduxjs/toolkit";

const detectionSlice = createSlice({
  name: "detect",
  initialState: {
    isCaptureStart: false,
  },
  reducers: {
    enableDetection: (state) => {
      state.isCaptureStart = !state.isCaptureStart;
    },
  },
});

export const { enableDetection } = detectionSlice.actions;
export default detectionSlice.reducer;
