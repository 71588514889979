export const painCheckbox = [
  {
    title: "1. How often do you experience knee pain?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "2. Walking on a flat surface?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "3. Going up or down stairs",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "4. Sitting or lying?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "5. Rising from sitting?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "6. Standing?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "7. Getting in/out of a car?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "8. Twisting/pivoting on your injured knee?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "9. How often are you aware of your knee problem",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title:
      "10. Have you modified your life style to avoid potentially damaging activities to your knee?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title:
      "11. How much are you troubled with lack of confidence in your knee?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
  {
    title: "12. In general how much difficulty do you have with your knee?",
    Never: false,
    Monthly: false,
    Weekly: false,
    Daily: false,
    Always: false,
  },
];

export const p = {
  p1: [
    { title: "1. How often do you experience knee pain?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p2: [
    { title: "2. Walking on a flat surface?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p3: [
    { title: "3. Going up or down stairs" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p4: [
    { title: "4. Sitting or lying?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p5: [
    { title: "5. Rising from sitting?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p6: [
    { title: "6. Standing?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p7: [
    { title: "7. Getting in/out of a car?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p8: [
    { title: "8. Twisting/pivoting on your injured knee?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p9: [
    { title: "9. How often are you aware of your knee problem" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p10: [
    {
      title:
        "10. Have you modified your life style to avoid potentially damaging activities to your knee?",
    },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p11: [
    {
      title:
        "11. How much are you troubled with lack of confidence in your knee?",
    },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
  p12: [
    { title: "12. In general how much difficulty do you have with your knee?" },
    {
      Never: false,
      Monthly: false,
      Weekly: false,
      Daily: false,
      Always: false,
    },
  ],
};
