import { useLocation } from "react-router-dom";
import { backIcon } from "../../mock/icons";
import { arrowIcon } from "../../mock/icons";
// import backIcon from "../../mock/icons/backIcon";

const WatchExercise = () => {
  const location = useLocation();
  const backIconImg = backIcon;
  const videoPath = location.state.vidPath;
  const videoName = location.state.name;

  return (
    <div>
      <div className="sticky top-0 bg-[#01526A] h-24 flex items-center justify-center">
        <a href="/exercise-list">
          <img src={arrowIcon} className="w-11 h-11 p-2" alt="back-arrow" />
        </a>
        <div className="text-white font-bold text-2xl flex flex-1 justify-center">
          {videoName}
        </div>
      </div>
      <div className="m-2 p-2">
        <video width="400" controls>
          <source src={videoPath} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default WatchExercise;
/**
 * padding : 4px 0px;
 * flex : 1 1 0%;
 */
