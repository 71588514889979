import { useDispatch, useSelector } from "react-redux";
import RomHeader from "./RomHeader";
import OpenCam from "./openCam";
import { useEffect, useState } from "react";
import StartDetection from "./startDetection";
import LoadModel from "./LoadModel";
import CameraAccessDialog from "../CameraAccessDialog";
import RomDialog from "./romDialog";
import useCameraAccess from "../../../hooks/useCameraAccess";
import { enableDetection } from "../../../utils/detectionSlice";

const RomSession = () => {
  const dispatch = useDispatch();

  const { enableCamera, isCamLoaded } = useCameraAccess();
  const [textBar, setTextBar] = useState(false);
  const [buttonSwitch, setButtonSwitch] = useState("Start Capture");
  const [showDialog, setShowDialog] = useState(0);

  const minAngleData = useSelector((store) => store.angleDetected.isMin);
  const differenceAngleData = useSelector(
    (store) => store.angleDetected.isDifference
  );
  const maxAngleData = useSelector((store) => store.angleDetected.isMax);

  const handleClick = () => {
    if (buttonSwitch === "Start Capture") {
      setButtonSwitch("Capture Done");
      setTextBar(true);
    } else if (buttonSwitch === "Capture Done") {
      console.log("before dialog");
      dispatch(enableDetection());
      setShowDialog(1);
    }
  };

  if (isCamLoaded) {
    // console.log("Load model", poseLandMarker, isCamLoaded);
  } else {
    console.log("camera disabled");
  }

  // useEffect(() => {
  //   console.log("function aclled");
  //   setPoseLandMarker(LoadModel());
  // }, [isCamLoaded]);

  return (
    <div className="">
      <RomHeader />
      <CameraAccessDialog enableCamera={enableCamera} />

      <div className="flex items-center flex-col">
        {!textBar && (
          <p className="text-2xl font-bold text-center py-4 h-fit">
            Please bring the knee to the middle and press Start Capture
          </p>
        )}
        {textBar && (
          <>
            <div className="flex flex-wrap w-full pt-8 gap-2 justify-evenly">
              <p className="w-14 h-14 text-center flex items-center justify-center rounded-full bg-black text-white">
                {Math.ceil(minAngleData)}
              </p>
              <p className="w-14 h-14 text-center flex items-center justify-center rounded-full bg-black text-white">
                {Math.ceil(maxAngleData - minAngleData)}
              </p>
              <p className="w-14 h-14 text-center flex items-center justify-center rounded-full bg-black text-white">
                {maxAngleData}
              </p>
            </div>
            <p className="pt-8 font-bold text-2xl">ROM</p>
          </>
        )}
        <div className="relative">
          <div className="h-[427px] w-[427px]">
            {console.log("before rendering opencam")}
            {isCamLoaded && <OpenCam displayCam={isCamLoaded} />}
          </div>
          <div className="flex flex-col items-center">
            <button
              id="enable-capture"
              onClick={() => {
                handleClick();
              }}
              // className="flex-1 rounded-lg p-2 w-max bg-[#21A18E] text-white cursor-pointer"
              className="w-72 h-20 rounded-full p-2 m-2 bg-[#21A18E] text-white disabled:bg-green-700"
            >
              {buttonSwitch}
            </button>
          </div>
        </div>
        {/* {<StartDetection startDetect={buttonSwitch} />} */}
        {<RomDialog showdialogbox={showDialog} />}
      </div>
    </div>
  );
};

export default RomSession;
