export const list = {
  exercise: [
    {
      exerciseId: "1",
      exerciseName: "Ankle Pumps",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Ankle-Pumps.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Ankle-Pumps.mp4",
    },
    {
      exerciseId: "2",
      exerciseName: "Bridging",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/bridging.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Bridging.mp4",
    },
    {
      exerciseId: "3",
      exerciseName: "Chair Push ups",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Chair-Ups.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Chair-Push-Ups.mp4",
    },
    {
      exerciseId: "4",
      exerciseName: "Gluteal Squeezes",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Gluteal-Squeezes.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Gluteal-Squeezes.mp4",
    },
    {
      exerciseId: "5",
      exerciseName: "Hamstring set",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Hamstring-set.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Hamstring-set.mp4",
    },
    {
      exerciseId: "6",
      exerciseName: "Heel Slides",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Heel-slides.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Heel-Slides.mp4",
    },
    {
      exerciseId: "7",
      exerciseName: "Hip Abduction",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Hip-abduction.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Hip-Abduction.mp4",
    },
    {
      exerciseId: "8",
      exerciseName: "Knee Extension",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Knee-extension.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Knee-Extension.mp4",
    },
    {
      exerciseId: "9",
      exerciseName: "Knee Slides",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Knee-slides.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Knee-Slides.mp4",
    },
    {
      exerciseId: "10",
      exerciseName: "Long arc Quads",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Long-arc-quads.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Long_Arc_Quads.mp4",
    },
    {
      exerciseId: "11",
      exerciseName: "Quad Sets",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Quad-sets.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Quad-Sets.mp4",
    },
    {
      exerciseId: "12",
      exerciseName: "Sitting Knee Extension",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Sitting-knee-extension.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Sitting-Knee-Extension.mp4",
    },
    {
      exerciseId: "13",
      exerciseName: "Sitting knee Flexion",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Sitting-knee-flexion.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Sitting-Knee-Flexion.mp4",
    },
    {
      exerciseId: "14",
      exerciseName: "Straight leg raises",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Straight-leg-raises.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Straigt_Leg_Raises.mp4",
    },
    {
      exerciseId: "15",
      exerciseName: "Terminal knee extension",
      exerciseImagePath:
        "/assets/ExerciseList/exercise-images/Terminal-knee-extension.png",
      exerciseVideoPath:
        "/assets/ExerciseList/exercise-video/Terminal-Knee-Extension.mp4",
    },
    {
      exerciseId: "16",
      exerciseName: "Toe up",
      exerciseImagePath: "/assets/ExerciseList/exercise-images/Toe-up.png",
      exerciseVideoPath: "/assets/ExerciseList/exercise-video/Toe-up.mp4",
    },
  ],
};
